import React,{Suspense, useState, Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {BrowserRouter as Router,Route,Redirect} from 'react-router-dom'
import Header from './modules/Header'
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {Typography} from '@material-ui/core'
import  {APP_URL} from './config.json';
import Grid from '@material-ui/core/Grid';

import {pathSpliter,authInfoSeter,accessChecker} from './lib/functions'
import {currentRouteSet,authInfoSet} from './actions/actions'; 
import PurchaseEntry from './modules/purchase/purchase_entry' 
import PurchaseReturnEntry from './modules/purchase/purchase_return_entry' 




import SignIn  from './modules/signin';
import Dashboard  from './modules/Dashboard';
import ModuleDetail  from './modules/ModuleDetail';
import UsersManage  from './modules/settings/UsersManage';
import warehousesManage  from './modules/settings/warehouses_manage';
import BranchesManage  from './modules/settings/branches_manage';
import InstitutionProfile  from './modules/settings/institution_profile';
import UnitManage  from './modules/settings/unit_manage';
import GroupManage  from './modules/settings/group_manage';
import CategoryManage  from './modules/settings/category_manage';
import ItemManage  from './modules/settings/item_manage';
import AccountManage  from './modules/settings/account_manage';
import LocationManage  from './modules/settings/location_manage';
import JournalEntry  from './modules/accounts/journal_entry';
import ExpenseEntry  from './modules/accounts/expense_entry';
import IncomeEntry  from './modules/accounts/income_entry';
import ContraEntry  from './modules/accounts/contra_entry';
import branchTran  from './modules/accounts/branch_transaction';
import DebitorReceiptEntry  from './modules/accounts/debitor_receipt_entry';
import PurchaseOrderEntry  from './modules/purchase/purchase_order_entry';
import CreditorPaymentEntry  from './modules/accounts/creditor_payment_entry';
import SalesOrderEntry  from './modules/sales/sales_order_entry';
import ViewPurchaseOrderVoucher  from './modules/purchase/view_purchase_order_voucher';
import ViewPurchaseVoucher  from './modules/purchase/view_purchase_voucher';
import QuickPurchaseOrderVoucher  from './modules/purchase/quick_purchase_order_voucher';
import ViewSalesOrderVoucher  from './modules/sales/view_sales_order_voucher';
import ViewSalesVoucher  from './modules/sales/view_sales_voucher';
import QuickPurchaseVoucher  from './modules/purchase/quick_purchase_voucher';
import PurchaseOrderRecord  from './modules/purchase/purchase_order_record';
import SalesOrderRecord  from './modules/sales/sales_order_record';
import PurchaseRecord  from './modules/purchase/purchase_record';
import SalesRecord  from './modules/sales/sales_record';
import PurchaseReturnRecord  from './modules/purchase/purchase_return_record';
import ViewPurchaseReturnVoucher  from './modules/purchase/view_purchase_return_voucher';
import QuickPurchaseReturnVoucher  from './modules/purchase/quick_purchase_return_voucher';
import QuickSalesOrderVoucher  from './modules/sales/quick_sales_order_voucher';
import QuickSalesVoucher  from './modules/sales/quick_sales_voucher';
import QuickPosVoucher  from './modules/sales/quick_pos';
import QuickSalesReturnVoucher  from './modules/sales/quick_sales_return_voucher';
import SalesEntry  from './modules/sales/sales_entry';
import SalesReturnEntry  from './modules/sales/sales_return_entry';
import SalesReturnRecord  from './modules/sales/sales_return_record';
import ViewSalesReturnVoucher  from './modules/sales/view_sales_return_voucher';
import TransferEntry  from './modules/inventory/transfer_entry';
import TransferRecord  from './modules/inventory/transfer_record';
import TransferPendingRecord  from './modules/inventory/transfer_pending_record';
import TransferReceiveRecord  from './modules/inventory/transfer_receive_record';
import ViewTransferVoucher  from './modules/inventory/transfer_voucher';
import ViewQuickTransferVoucher  from './modules/inventory/quick_transfer_voucher';
import ManufacturingJournalEntry  from './modules/manufacturing/manufacturing_journal_entry';
import viewManufacturingVoucher  from './modules/manufacturing/view_manufacturing_voucher';
import quickManufacturingVoucher  from './modules/manufacturing/quick_manufacturing_voucher';
import ManufacturingRecord  from './modules/manufacturing/manufacturing_record';


import EmployeeEntry  from './modules/hrpayroll/employee_entry';
import DepartmentEntry  from './modules/hrpayroll/department_entry';
import DesignationEntry  from './modules/hrpayroll/designation_entry';
import SalaryPaymentEntry  from './modules/hrpayroll/salary_payment_entry';
import ServiceEntry  from './modules/service/service_entry';
import viewServiceVouchers  from './modules/service/view_service_voucher';
import quickServiceVoucher  from './modules/service/quick_service_voucher';
import ServiceRecord  from './modules/service/service_record';
import ServiceExpenseEntry  from './modules/service/service_expense_entry';
import viewServiceExpenseVoucher  from './modules/service/view_service_expense_voucher';
import ServiceExpenseRecord  from './modules/service/service_expense_record';
import quickServiceExpenseVoucher  from './modules/service/quick_service_expense_voucher';
import CustomerBalance  from './modules/reports/debitors_balance';

import CreditorsBalance  from './modules/reports/creditors_balance';
import CustomerBalanceLedger  from './modules/reports/debitor_balance_ledger';
import CreditorBalanceLedger  from './modules/reports/creditor_balance_ledger';
import AccountBalance  from './modules/reports/account_balance';
import AccountLedger  from './modules/reports/account_ledger';
import CapitalsBalance  from './modules/reports/capitals_balance';
import CapitalLedger  from './modules/reports/capital_ledger';
import IndirectExpensesBalance  from './modules/reports/indirect_expenses_balance';
import IndirectIncomeBalance  from './modules/reports/indirect_income_balance';
import IndirectExpenseLedger  from './modules/reports/indirect_expense_ledger';
import IndirectIncomeLedger  from './modules/reports/indirect_income_ledger';
import SalesLedger  from './modules/reports/sales_ledger';
import SalesReturnLedger  from './modules/reports/sales_return_ledger';
import PurchaseLedger  from './modules/reports/purchase_ledger';
import PurchaseReturnLedger  from './modules/reports/purchase_return_ledger';
import ServiceLedger  from './modules/reports/service_ledger';
import ServiceExpenseLedger  from './modules/reports/service_expense_ledger';
import TaxLedger  from './modules/reports/tax_ledger';
import DirectExpenseBalance  from './modules/reports/direct_expense_balance';
import DirectExpenseLedger  from './modules/reports/direct_expense_ledger';
import DirectIncomeBalance  from './modules/reports/direct_income_balance';
import DirectIncomeLedger  from './modules/reports/direct_income_ledger';
import BalanceSheet  from './modules/reports/balance_sheet';
import ProfitLoss  from './modules/reports/profit_loss';
import ItemStock  from './modules/inventory/item_stock';
import ItemLedger  from './modules/inventory/item_ledger';
import loanBalance  from './modules/reports/loan_balance';
import fixedAssetBalance  from './modules/reports/fixed_asset_balance';
import loanLedger  from './modules/reports/loan_ledger';
import trialBalance  from './modules/reports/trial_balance';
import itemAdjustment  from './modules/inventory/item_adjustment';
import fixedAssetLedger  from './modules/reports/fixed_asset_ledger';
import AdjustmentRecord  from './modules/inventory/adjustment_record';
import QuickDebitorReceipt  from './modules/accounts/quick_debitor_receipt';
import UserAccess  from './modules/user_access';
import DebtorReceiptRecord  from './modules/reports/debtor_receipt_record';
import CreditorPaymentRecord  from './modules/reports/creditor_payment_record';
import ExpenseRecord  from './modules/reports/expense_record';
import IncomeRecord  from './modules/reports/income_record';
import JournalRecord  from './modules/reports/journal_record';
import ContraRecord  from './modules/reports/contra_record';
import SalaryReport  from './modules/reports/salary_report';
import AttendanceEntry  from './modules/hrpayroll/attendance_entry';
import monthlySalaryReport  from './modules/reports/monthly_salary_report';
import viewSalaryVoucher  from './modules/hrpayroll/view_salary_voucher';
import itemBarcode  from './modules/settings/item_barcode';
import quickChallanVoucher  from './modules/sales/quick_challan_voucher';
import debtorLedgerOwn  from './modules/reports/debtor_ledger_own';
import salesOrderEntryOwn  from './modules/sales/sales_order_entry_own';
import salesOrderRecordOwn  from './modules/sales/sales_order_record_own';
import emiDueList  from './modules/reports/emi_due_list';
import itemWiseProfit  from './modules/reports/item_wise_profit';
import itemReOrderList  from './modules/inventory/item_re_order_list';
import branchTranPendingList  from './modules/reports/branch_tran_pending_list';
import branchTranReceiveList  from './modules/reports/branch_tran_receive_list';
import branchTranTransferList  from './modules/reports/branch_tran_transfer_list';
import branchBalanceReport  from './modules/reports/branch_balance_report';
import branchLedger  from './modules/reports/branch_ledger';
import dailyLedger  from './modules/reports/daily_ledger';
import statement  from './modules/reports/statement';
import smsSender  from './modules/settings/sms_sender';
import AdvanceTranEntry  from './modules/accounts/advance_transaction_entry';
import AdvanceTranRecord  from './modules/accounts/advance_tran_record';
import AdvanceDebtorBalance  from './modules/reports/advance_debtor_balance';
import AdvanceCreditorBalance  from './modules/reports/advance_creditor_balance';
import QuotationEntry  from './modules/sales/quotation_entry';
import quickQuotationVoucher  from './modules/sales/quick_quotation_voucher'; 
import SalesQuotationRecord  from './modules/sales/sales_quotation_record'; 
import CRMCustomerEntry  from './modules/crm/customer_entry'; 
import CustomerEntry  from './modules/settings/customer_entry'; 
import ExpenseCategory  from './modules/settings/expense_category'; 

import customerPendingList  from './modules/crm/customer_pending_list'; 
import customerApprovedList  from './modules/crm/customer_approved_list'; 
import customerRejectedList  from './modules/crm/customer_rejected_list'; 
import userWiseCustomerReport  from './modules/crm/user_wise_customer_report'; 
import ManageModel  from './modules/settings/model_manage'; 
import ManageOrigin  from './modules/settings/origin_manage'; 

import expenseRecognitionEntry  from './modules/accounts/expense_recognition_entry'; 
import expenseRecognitionRecord  from './modules/reports/expense_recognition_record'; 
import collectionGroupEntry  from './modules/settings/collection_group_entry'; 
import collectionEntryWithReport  from './modules/accounts/collection_entry_with_report'; 
import PosEntry  from './modules/sales/pos_entry'; 
import collectionReport  from './modules/reports/collection_report'; 
import sheetPrintOfCollection  from './modules/reports/sheet_print_of_collection'; 
import ProductReplace  from './modules/sales/product_replace'; 
import ProductReplaceRecord  from './modules/sales/product_replace_record'; 
import quickReplaceVoucher  from './modules/sales/quick_replace_voucher';
import SupplierEntry  from './modules/settings/supplier_entry';
import BillWiseProfitLoss  from './modules/reports/bill_wise_profit_loss';





const drawerWidth = 180;
const useStyles = makeStyles((theme)=>({
  rootApp:{
    backgroundColor:'linear-gradient(to bottom, #33ccff 0%, #ff99cc 100%);'
  },
  navTopSection:{
    height:'7px',
    width:'100%',
    background: '#9CE1E7'
  },
  navTopSection:{
    height:'7px',
    background: '#9CE1E7'
  },
root: {
display: 'flex',
},
toolbarspace:{
display: 'flex',
alignItems: 'center',
justifyContent: 'flex-end',
padding: theme.spacing(0, 1),
...theme.mixins.toolbar,
},
content: {
flexGrow: 1,
padding: theme.spacing(3),
}
}))


const App = ({authInfo,currentRouteSet,currentRoute,authInfoSet})=>{
    useEffect(()=>{
      currentRouteSet(pathSpliter(window.location.pathname,1))
      authInfoSet(authInfoSeter);
    },[]);


    let classes = useStyles(); 
    
  return (
        <Router> 
        {
          authInfo!=null?
          (
          <Fragment>
           
         <div className={classes.navTopSection}></div>
         <div className={classes.rootApp} >
         <div className={classes.root}>
         <Header/> 

         <main className={classes.content} >
          <div className={classes.toolbarspace}/> 
          <Suspense fallback={<b>Loading...</b>}> 

          {/* <Grid container>
  <Grid item xs={12} sm={12}> */}
    <h5 style={{
         fontWeight: 'bold',fontFamily: 'cursive',margin: '-15px 3px 0px',padding: '0',textAlign:'right',fontSize:'12px',
         color:'#378100',marginRight:'20px'
         }}>  {authInfo.userInfo  != undefined ?authInfo.userInfo.branch_name :''}    </h5>
         {/* </Grid> */}
{/* </Grid> */}
             <Route exact path="/" component={Dashboard} />
             <Route exact path="/dashboard" component={Dashboard} />
             
             <Route exact path="/crm" component={ModuleDetail} />
             <Route exact path="/settings"  component={ModuleDetail} />
             <Route exact path="/accounts"  component={ModuleDetail} />
             <Route exact path="/hrpayroll"  component={ModuleDetail} />
             <Route exact path="/manufacturing"  component={ModuleDetail} />
             <Route exact path="/purchase"  component={ModuleDetail} />
             <Route exact path="/inventory"  component={ModuleDetail} />
             <Route exact path="/order"  component={ModuleDetail} />
             <Route exact path="/sales"  component={ModuleDetail} />
             <Route exact path="/service"  component={ModuleDetail} />
             <Route exact path="/reports"  component={ModuleDetail} />
             <Route exact path="/quotation"  component={ModuleDetail} />

             {/* CRM */}
             <Route exact path="/crm/customer-entry"  component={CRMCustomerEntry} />
             <Route exact path="/sales/customer-entry"  component={CustomerEntry} />
             <Route exact path="/crm/customer-pending-list"  component={customerPendingList} />
             <Route exact path="/crm/customer-approved-list"  component={customerApprovedList} />
             <Route exact path="/crm/customer-rejected-list"  component={customerRejectedList} />
             <Route exact path="/crm/user-wise-customer-report"  component={userWiseCustomerReport} />
             <Route exact path="/accounts/collection-group-entry"  component={collectionGroupEntry} />
             <Route exact path="/accounts/collection-entry-with-report"  component={collectionEntryWithReport} />
             <Route exact path="/reports/collection-record"  component={collectionReport} />
             <Route exact path="/reports/sheet-print-of-collection"  component={sheetPrintOfCollection} />
             <Route exact path="/reports/bill-wise-profit-loss"  component={BillWiseProfitLoss} />
             
             <Route exact path="/sales/product-replace"  component={ProductReplace} />
             <Route exact path="/sales/product-replace/:id"  component={ProductReplace} />
             <Route exact path="/sales/product-replace-record"  component={ProductReplaceRecord} />


             <Route exact path="/sales/pos-entry"  component={PosEntry} />
             <Route exact path="/sales/pos-update/:id"  component={PosEntry} />

             

             <Route exact path="/settings/model-manage"  component={ManageModel} />
             <Route exact path="/settings/origin-manage"  component={ManageOrigin} />
             <Route exact path="/settings/expense-category"  component={ExpenseCategory} />


             {/* Purchase start*/}
             
             
             <Route exact path="/reports/item-wise-profit"  component={itemWiseProfit} />
             <Route exact path="/inventory/item-re-order-list"  component={itemReOrderList} />
             <Route exact path="/settings/sms-sender"  component={smsSender} />
             <Route exact path="/reports/journal-record"  component={JournalRecord} />
             <Route exact path="/reports/contra-record"  component={ContraRecord} />
             <Route exact path="/reports/balance-sheet"  component={BalanceSheet} />
             <Route exact path="/reports/debitors-balance"  component={CustomerBalance} />
             <Route exact path="/reports/creditors-balance"  component={CreditorsBalance} />
             <Route exact path="/reports/debitor-balance-ledger"  component={CustomerBalanceLedger} />
             <Route exact path="/reports/creditor-balance-ledger"  component={CreditorBalanceLedger} />
             <Route exact path="/reports/account-balance"  component={AccountBalance} />
             <Route exact path="/reports/account-ledger"  component={AccountLedger} />
             <Route exact path="/reports/daily-ledger"  component={dailyLedger} />
             <Route exact path="/reports/statement"  component={statement} />
             <Route exact path="/reports/capitals-balance"  component={CapitalsBalance} />
             <Route exact path="/reports/capital-ledger"  component={CapitalLedger} />
             <Route exact path="/reports/indirect-expenses-balance"  component={IndirectExpensesBalance} />
             <Route exact path="/reports/indirect-income-balance"  component={IndirectIncomeBalance} />
             <Route exact path="/reports/indirect-expense-ledger"  component={IndirectExpenseLedger} />
             <Route exact path="/reports/indirect-income-ledger"  component={IndirectIncomeLedger} />
             <Route exact path="/reports/sales-ledger"  component={SalesLedger} />
             <Route exact path="/reports/sales-return-ledger"  component={SalesReturnLedger} />
             <Route exact path="/reports/purchase-ledger"  component={PurchaseLedger} />
             <Route exact path="/reports/purchase-return-ledger"  component={PurchaseReturnLedger} />
             <Route exact path="/reports/service-ledger"  component={ServiceLedger} />
             <Route exact path="/reports/service-expense-ledger"  component={ServiceExpenseLedger} />
             <Route exact path="/reports/tax-ledger"  component={TaxLedger} />
             <Route exact path="/reports/direct-expense-balance"  component={DirectExpenseBalance} />
             <Route exact path="/reports/direct-expense-ledger"  component={DirectExpenseLedger} />
             <Route exact path="/reports/direct-income-balance"  component={DirectIncomeBalance} />
             <Route exact path="/reports/direct-income-ledger"  component={DirectIncomeLedger} />
             <Route exact path="/reports/profit-loss"  component={ProfitLoss} />
             <Route exact path="/reports/item-stock-report"  component={ItemStock} />
             <Route exact path="/inventory/item-stock-report"  component={ItemStock} />
             <Route exact path="/inventory/item-ledger"  component={ItemLedger} />
             <Route exact path="/reports/loan-balance"  component={loanBalance} />
             <Route exact path="/reports/fixed-asset-balance"  component={fixedAssetBalance} />
             <Route exact path="/reports/loan-ledger"  component={loanLedger} />
             <Route exact path="/reports/fixed-asset-ledger"  component={fixedAssetLedger} />
             <Route exact path="/reports/trial-balance"  component={trialBalance} />
             <Route exact path="/inventory/item-adjustment"  component={itemAdjustment} />
             <Route exact path="/inventory/item-adjustment/:id"  component={itemAdjustment} />
             <Route exact path="/inventory/adjustment-record"  component={AdjustmentRecord} />
             <Route exact path="/settings/user-access/:id"  component={UserAccess} />

             <Route exact path="/accounts/quick-debitor-receipt/:id"  component={QuickDebitorReceipt} />
             <Route exact path="/reports/salary-report"  component={SalaryReport} />
             <Route exact path="/hrpayroll/salary-report"  component={SalaryReport} />
             <Route exact path="/sales/quick-challan-voucher/:id"  component={quickChallanVoucher} />
             <Route exact path="/reports/debtor-ledger-own"  component={debtorLedgerOwn} />
             <Route exact path="/reports/emi-due-list"  component={emiDueList} />
             <Route exact path="/reports/branch-tran-pending-list"  component={branchTranPendingList} />
             <Route exact path="/reports/branch-tran-receive-list"  component={branchTranReceiveList} />
             <Route exact path="/reports/branch-tran-transfer-list"  component={branchTranTransferList} />
             <Route exact path="/reports/expense-recognition-record"  component={expenseRecognitionRecord} />

             

             
             

             
             

             <Route exact path="/sales/sales-order-entry-own"  component={salesOrderEntryOwn} />
             <Route exact path="/sales/sales-order-record-own"  component={salesOrderRecordOwn} />
             <Route exact path="/sales/sales-entry"  component={SalesEntry} />
             <Route exact path="/quotation/quotation-entry"  component={QuotationEntry} />
             <Route exact path="/quotation/quotation-entry/:id"  component={QuotationEntry} />
             <Route exact path="/manufacturing/manufacturing-journal-entry"  component={ManufacturingJournalEntry} />
             <Route exact path="/manufacturing/manufacturing-journal-update/:id"  component={ManufacturingJournalEntry} />
             <Route exact path="/manufacturing/view-manufacturing-voucher"  component={viewManufacturingVoucher} />
             <Route exact path="/manufacturing/quick-manufacturing-voucher/:id"  component={quickManufacturingVoucher} />
             <Route exact path="/sales/quick-replace-voucher/:id"  component={quickReplaceVoucher} />
             <Route exact path="/manufacturing/manufacturing-record"  component={ManufacturingRecord} />
             <Route exact path="/reports/manufacturing-record"  component={ManufacturingRecord} />
             <Route exact path="/hrpayroll/attendance-entry"  component={AttendanceEntry} />
             <Route exact path="/reports/monthly-salary-report"  component={monthlySalaryReport} />
             <Route exact path="/hrpayroll/monthly-salary-report"  component={monthlySalaryReport} />
             <Route exact path="/hrpayroll/salary-voucher/:id/:id/:id"  component={viewSalaryVoucher} />
             <Route exact path="/settings/item-barcode/:id"  component={itemBarcode} />
             
             
             <Route exact path="/hrpayroll/salary-payment-entry"  component={SalaryPaymentEntry} />
             <Route exact path="/hrpayroll/employee-entry"  component={EmployeeEntry} />
             <Route exact path="/hrpayroll/department-entry"  component={DepartmentEntry} />
             <Route exact path="/hrpayroll/designation-entry"  component={DesignationEntry} />
             <Route exact path="/purchase/purchase-entry"  component={PurchaseEntry} />
             <Route exact path="/order/sales-order-entry"  component={SalesOrderEntry} />
             <Route exact path="/order/sales-order-update/:id"  component={SalesOrderEntry} />
             <Route exact path="/sales/sales-update/:id"  component={SalesEntry} />
             <Route exact path="/purchase/purchase-return-entry"  component={PurchaseReturnEntry} />
             <Route exact path="/purchase/purchase-return-record"  component={PurchaseReturnRecord} />
             <Route exact path="/reports/purchase-return-record"  component={PurchaseReturnRecord} />
             <Route exact path="/quotation/quick-quotation-voucher/:id"  component={quickQuotationVoucher} />
             <Route exact path="/purchase/supplier-entry"  component={SupplierEntry} />

             
             <Route exact path="/sales/sales-return-record"  component={SalesReturnRecord} />
             <Route exact path="/quotation/quotation-record"  component={SalesQuotationRecord} />
             <Route exact path="/purchase/order-to-purchase/:id"  component={PurchaseEntry} />
             <Route exact path="/sales/order-to-sales/:id"  component={SalesEntry} />
             <Route exact path="/sales/quotation-to-sales/:id"  component={SalesEntry} />
             <Route exact path="/order/quotation-to-order/:id"  component={SalesOrderEntry} />
             <Route exact path="/order/purchase-order-entry"  component={PurchaseOrderEntry} />
             <Route exact path="/order/purchase-order-update/:id"  component={PurchaseOrderEntry} />
             <Route exact path="/purchase/purchase-update/:id"  component={PurchaseEntry} />
             <Route exact path="/purchase/purchase-return-update/:id"  component={PurchaseReturnEntry} />
             <Route exact path="/sales/sales-return-update/:id"  component={SalesReturnEntry} />
             <Route exact path="/purchase/view-purchase-voucher"  component={ViewPurchaseVoucher} />
             <Route exact path="/sales/view-sales-voucher"  component={ViewSalesVoucher} />
             <Route exact path="/purchase/view-purchase-return-voucher"  component={ViewPurchaseReturnVoucher} />
             <Route exact path="/sales/view-sales-return-voucher"  component={ViewSalesReturnVoucher} />
             <Route exact path="/order/view-purchase-order-voucher"  component={ViewPurchaseOrderVoucher} />
             <Route exact path="/order/view-sales-order-voucher"  component={ViewSalesOrderVoucher} />
             <Route exact path="/order/quick-purchase-order-voucher/:id"  component={QuickPurchaseOrderVoucher} />
             <Route exact path="/order/quick-sales-order-voucher/:id"  component={QuickSalesOrderVoucher} />
             <Route exact path="/sales/quick-sales-return-voucher/:id"  component={QuickSalesReturnVoucher} />
             <Route exact path="/sales/quick-sales-voucher/:id"  component={QuickSalesVoucher} />
             <Route exact path="/sales/quick-pos/:id"  component={QuickPosVoucher} />
             <Route exact path="/purchase/quick-purchase-voucher/:id"  component={QuickPurchaseVoucher} />
             <Route exact path="/purchase/quick-purchase-return-voucher/:id"  component={QuickPurchaseReturnVoucher} />
             <Route exact path="/order/purchase-order-record"  component={PurchaseOrderRecord} />
             <Route exact path="/order/sales-order-record"  component={SalesOrderRecord} />
             <Route exact path="/purchase/purchase-record"  component={PurchaseRecord} />
             <Route exact path="/reports/purchase-record"  component={PurchaseRecord} />
             <Route exact path="/sales/sales-record"  component={SalesRecord} />
             <Route exact path="/reports/sales-record"  component={SalesRecord} />
             <Route exact path="/sales/sales-return-entry"  component={SalesReturnEntry} />
             <Route exact path="/reports/sales-return-record"  component={SalesReturnRecord} />


             <Route exact path="/service/service-entry"  component={ServiceEntry} />
             <Route exact path="/service/view-service-vouchers"  component={viewServiceVouchers} />
             <Route exact path="/service/quick-service-voucher/:id"  component={quickServiceVoucher} />
             <Route exact path="/service/service-update/:id"  component={ServiceEntry} />
             <Route exact path="/service/service-record"  component={ServiceRecord} />
             <Route exact path="/service/service-expense-record"  component={ServiceExpenseRecord} />


             <Route exact path="/service/service-expense-entry"  component={ServiceExpenseEntry} />
             <Route exact path="/service/service-expense-update/:id"  component={ServiceExpenseEntry} />
             <Route exact path="/service/view-service-expense-vouchers"  component={viewServiceExpenseVoucher} />
             <Route exact path="/service/quick-service-expense-voucher/:id"  component={quickServiceExpenseVoucher} />


             


             <Route exact path="/inventory/transfer-entry"  component={TransferEntry} />
             <Route exact path="/inventory/view-transfer-voucher"  component={ViewTransferVoucher} />
             <Route exact path="/inventory/quick-transfer-voucher/:id"  component={ViewQuickTransferVoucher} />
             <Route exact path="/inventory/transfer-record"  component={TransferRecord} />
             <Route exact path="/inventory/transfer-pending-record"  component={TransferPendingRecord} />
             <Route exact path="/inventory/transfer-receive-record"  component={TransferReceiveRecord} />
             <Route exact path="/inventory/transfer-update/:id"  component={TransferEntry} />
             
             {/* Purchase end */}
             <Route exact path="/settings/warehouse-manage"  component={warehousesManage} />
             <Route exact  path="/settings/user-manage"  component={UsersManage} />
             <Route exact path="/settings/branch-manage"  component={BranchesManage} />
             <Route exact path="/settings/institution-profile"  component={InstitutionProfile} />
             <Route exact path="/settings/unit-manage"  component={UnitManage} />
             <Route exact path="/settings/group-manage"  component={GroupManage} />
             <Route exact path="/settings/category-manage"  component={CategoryManage} />
             <Route exact path="/settings/item-manage"  component={ItemManage} />

             <Route exact path="/accounts/account-entry"  component={AccountManage} />
             <Route exact path="/accounts/expense-recognition-entry"  component={expenseRecognitionEntry} />

             <Route exact path="/accounts/location-manage"  component={LocationManage} />
             <Route exact path="/sales/location-manage"  component={LocationManage} />
             <Route exact path="/accounts/journal-entry"  component={JournalEntry} />
             <Route exact path="/accounts/expense-entry"  component={ExpenseEntry} />
             <Route exact path="/accounts/income-entry"  component={IncomeEntry} />
             <Route exact path="/accounts/contra-entry"  component={ContraEntry} />
             <Route exact path="/accounts/advance-tran-entry"  component={AdvanceTranEntry} />
             <Route exact path="/reports/advance-tran-record"  component={AdvanceTranRecord} />
             <Route exact path="/reports/advance-debtor-balance"  component={AdvanceDebtorBalance} />
             <Route exact path="/reports/advance-creditor-balance"  component={AdvanceCreditorBalance} />
             <Route exact path="/accounts/branch-tran"  component={branchTran} />
             <Route exact path="/accounts/creditor-payment-entry"  component={CreditorPaymentEntry} />
             <Route exact path="/accounts/debitor-receipt-entry"  component={DebitorReceiptEntry} />
             <Route exact path="/reports/debtor-receipt-record"  component={DebtorReceiptRecord} />
             <Route exact path="/reports/creditor-payment-record"  component={CreditorPaymentRecord} />
             <Route exact path="/reports/expense-record"  component={ExpenseRecord} />
             <Route exact path="/reports/income-record"  component={IncomeRecord} />
             <Route exact path="/reports/branch-balance-report"  component={branchBalanceReport} />
             <Route exact path="/reports/branch-ledger"  component={branchLedger} />
             


             </Suspense>
          </main> 
          </div>
         </div>
         </Fragment>
            
          ):(   
          <>
                <Suspense fallback={<b style={{textAlign:'center'}}>Loading...</b>}>
                <Route exact path="/" component={SignIn} />
                </Suspense>
                </>
          )
            
            
           
        }
       
        </Router>
  );
}
const mapStateToPops = (state)=>{
  return {
    currentRoute:state.currentRouteReducer,
    authInfo:state.authInfoReducer
  }
}
export default connect(mapStateToPops,{currentRouteSet,authInfoSet})(App);