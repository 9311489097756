import React,{Fragment,useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../actions/actions';
import {pathSpliter} from '../lib/functions';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {APP_URL,API_URL} from '../config.json';
import './global.css'
import './dashboard.css'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import {Typography} from '@material-ui/core';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import NoteIcon from '@material-ui/icons/Note';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import BuildIcon from '@material-ui/icons/Build';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ShopIcon from '@material-ui/icons/Shop';
import Chart from "react-google-charts";
import AnalogueClock from 'react-analogue-clock';
import DigitalClock from 'react-digital-clock';
import moment from 'moment';
import axios from 'axios';

import {currentDateTime,convertNumberToWords, dateTimeFormat,dateFormat,currentDateStartTime,currentDateEndTime} from '.././lib/functions'


import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const drawerWidth = 250;


const clockOptions = {
  baseColor: '#ffffff',
  borderColor: '#000000',
  borderWidth: 5,
  centerColor: '#000000',
  handColors: {
      hour: '#000000',
      minute: '#000000',
      second: '#000000',
  },
  notchColor: '#000000',
  numbersColor: '#000000',
  showNumbers: true,
  size: 250,
}


const useStyles = makeStyles((theme) => ({
  box:{
    color: '#e1f8fb',
    height: '177px',
    margin: '0px',
    background: '#23B7A7',
    textAlign: 'center',
    textDecoration: 'none',
    borderRadius: '20px',
    marginTop: '10px',
    width: '23%',
    margin: '1%'
  },
  
  boxTitle:{
    color: '#484848',
    fontWeight:'bold',
    fontSize:'18px',
    margin:'0px'
  },
  '@global': {
    '.MuiBox-root':{
      paddingBottom:'0px !important',
      paddingTop: '20px'
    },
    '.MuiBox-root p':{
      color:'white'
    },
    '.MuiBox-root svg':{
      color:'white',
      fontWidth:'bold'
    }
  }
}));

const logout = ()=>{
  sessionStorage.clear();
  window.location.href = `${APP_URL}`
}





const Dashboard = ({location,currentRoute,currentRouteSet,authInfo})=>{
  useEffect(()=>{
      currentRouteSet(pathSpliter(location.pathname,1));
      getDailyBalances()
      getBalances()

      // getLastSevenDaysSalesExpense()
      // getExpenses()
      // getTopSoldItems()
      getLast4MonthsDates()
      getLastFourMonthSales()
     

  },[]);

  let [amount,amountSet] =  useState(10000)


  let [today_cash,today_cash_set] =  useState(0)
  let [today_bank_balance,today_bank_balance_set] =  useState(0)
  let [today_expense,today_expense_set] =  useState(0)
  let [today_income,today_income_set] =  useState(0)
  let [today_sale,today_sale_set] =  useState(0)
  let [today_profit_loss,today_profit_loss_set] =  useState(0)

  let [bank_balance,bank_balance_set] =  useState(0)
  let [cash_in_hand,cash_in_hand_set] =  useState(0)
  let [creditor_balance,creditor_balance_set] =  useState(0)
  let [debtor_balance,debtor_balance_set] =  useState(0)
  


  let [expenses,expensesSet] = useState([])
  let [items,itemsSet] = useState([])

  let [fromDate,fromDateSet] = useState(currentDateStartTime())
  let [toDate,toDateSet] = useState(currentDateEndTime())


  let [dateOfLastSeven,dateOfLastSevenSet] = useState(moment().subtract(6,'d').toISOString())
  let [dateOfLastSix,dateOfLastSixSet] = useState(moment().subtract(5,'d').toISOString())
  let [dateOfLastFive,dateOfLastFiveSet] = useState(moment().subtract(4,'d').toISOString())
  let [dateOfLastFour,dateOfLastFourSet] = useState(moment().subtract(3,'d').toISOString())
  let [dateOfLastThree,dateOfLastThreeSet] = useState(moment().subtract(2,'d').toISOString())
  let [dateOfLastTwo,dateOfLastTwoSet] = useState(moment().subtract(1,'d').toISOString())
  let [dateOfLastOne,dateOfLastOneSet] = useState(moment().subtract(0,'d').toISOString())


  let [lastOneSold,lastOneSoldSet] = useState(0)
  let [lastTwoSold,lastTwoSoldSet] = useState(0)
  let [lastThreeSold,lastThreeSoldSet] = useState(0)
  let [lastFourSold,lastFourSoldSet] = useState(0)
  let [lastFiveSold,lastFiveSoldSet] = useState(0)
  let [lastSixSold,lastSixSoldSet] = useState(0)
  let [lastSevenSold,lastSevenSoldSet] = useState(0)

  let [lastOneExpense,lastOneExpenseSet] = useState(0)
  let [lastTwoExpense,lastTwoExpenseSet] = useState(0)
  let [lastThreeExpense,lastThreeExpenseSet] = useState(0)
  let [lastFourExpense,lastFourExpenseSet] = useState(0)
  let [lastFiveExpense,lastFiveExpenseSet] = useState(0)
  let [lastSixExpense,lastSixExpenseSet] = useState(0)
  let [lastSevenExpense,lastSevenExpenseSet] = useState(0)
  let [fourMonthSales,fourMonthSalesSet] = useState([])
  


  const getLast4MonthsDates = (currentDate) => {
    const last4MonthsDates = [];
  
    for (let i = 0; i < 4; i++) {
      const startOfMonth = moment(currentDate).subtract(i, 'months').startOf('month');
      const endOfMonth = moment(currentDate).subtract(i, 'months').endOf('month');
  
      last4MonthsDates.push({
        startDate: startOfMonth.format('YYYY-MM-DD'),
        endDate: endOfMonth.format('YYYY-MM-DD'),
      });
    }
  
    return last4MonthsDates;
  };



  const getLastFourMonthSales = async ()=>{
    if (getLast4MonthsDates().length !== 0) {
      var amounts = [];
    
      // Use Promise.all to wait for all asynchronous requests to complete
     await  Promise.all(
        getLast4MonthsDates().map((month) =>
          axios
            .post(
              `${API_URL}/api/get-sales-amount`,
              {
                fromDate: month.startDate,
                toDate: month.endDate,
              },
              {
                headers: { 'auth-token': authInfo.token },
              }
            )
            .then((res) => res.data[0])
           
        )
      )
        .then((results) => {
          // Filter out null values (failed requests) and set the state
          amounts = results.filter((result) => result !== null);
          fourMonthSalesSet(amounts);
        })
      
    }
    
   
    
  }

  

  const getExpenses = async ()=>{
    await axios.post(`${API_URL}/api/get-indirect-expense-balance`,{fromDate:moment(dateOfLastSeven).startOf('day').toISOString()
    ,toDate: moment(dateOfLastOne).endOf('day').toISOString()},{headers:{'auth-token':authInfo.token}}).then(res=>{
      //expensesSet(res.data.expenses)
      let temExpense = []
      res.data.expenses.map((exp)=>{

        temExpense.push([exp.acc_name,exp.balance])
      });
      expensesSet([...temExpense])



    })
  }

  const getTopSoldItems = async ()=>{
    await axios.post(`${API_URL}/api/get-detail-stock`,{fromDate:moment(dateOfLastSeven).startOf('day').toISOString()
    ,toDate: moment(dateOfLastOne).endOf('day').toISOString()},{headers:{'auth-token':authInfo.token}}).then(res=>{
      itemsSet(res.data)
      let topItems = []
      res.data.map((item)=>{

        topItems.push([item.item_name,item.sale_qty])
      });
      itemsSet([...topItems])

    })
  }

  const getLastSevenDaysSalesExpense = async ()=>{
    // sales
    await axios.post(`${API_URL}/api/get-sales-balance`,{fromDate:moment(dateOfLastOne).startOf('day').toISOString()
    ,toDate: moment(dateOfLastOne).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastOneSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`,{fromDate:moment(dateOfLastTwo).startOf('day').toISOString()
    ,toDate: moment(dateOfLastTwo).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastTwoSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`,{fromDate:moment(dateOfLastThree).startOf('day').toISOString()
    ,toDate: moment(dateOfLastThree).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastThreeSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`,{fromDate:moment(dateOfLastFour).startOf('day').toISOString()
    ,toDate: moment(dateOfLastFour).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastFourSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`,{fromDate:moment(dateOfLastFive).startOf('day').toISOString()
    ,toDate: moment(dateOfLastFive).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastFiveSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`,{fromDate:moment(dateOfLastSix).startOf('day').toISOString()
    ,toDate: moment(dateOfLastSix).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastSixSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`,{fromDate:moment(dateOfLastSeven).startOf('day').toISOString()
    ,toDate: moment(dateOfLastSeven).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastSevenSoldSet(res.data.total_balance)
    })
    // Expense 
    await axios.post(`${API_URL}/api/get-indirect-expense-balance`,{fromDate:moment(dateOfLastOne).startOf('day').toISOString()
    ,toDate: moment(dateOfLastOne).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastOneExpenseSet(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-indirect-expense-balance`,{fromDate:moment(dateOfLastTwo).startOf('day').toISOString()
    ,toDate: moment(dateOfLastTwo).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastTwoExpenseSet(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-indirect-expense-balance`,{fromDate:moment(dateOfLastThree).startOf('day').toISOString()
    ,toDate: moment(dateOfLastThree).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastThreeExpenseSet(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-indirect-expense-balance`,{fromDate:moment(dateOfLastFour).startOf('day').toISOString()
    ,toDate: moment(dateOfLastFour).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastFourExpenseSet(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-indirect-expense-balance`,{fromDate:moment(dateOfLastFive).startOf('day').toISOString()
    ,toDate: moment(dateOfLastFive).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastFiveExpenseSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-indirect-expense-balance`,{fromDate:moment(dateOfLastSix).startOf('day').toISOString()
    ,toDate: moment(dateOfLastSix).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastSixExpenseSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-indirect-expense-balance`,{fromDate:moment(dateOfLastSeven).startOf('day').toISOString()
    ,toDate: moment(dateOfLastSeven).endOf('day').toISOString(),type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      lastSevenExpenseSet(res.data.total_balance)
    })

  }


  const getBalances = async ()=>{
    await axios.post(`${API_URL}/api/get-accounts-balance`,{"accType":"'cash_in_hand'",type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      cash_in_hand_set(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-accounts-balance`,{"accType":"'bank_account'",type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      bank_balance_set(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sundry-creditor-balance`,{type:"head_total",accType:"'creditor'"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      creditor_balance_set(res.data.total_balance)
    })
    // await axios.post(`${API_URL}/api/get-sundry-debitor-balance`,{type:"head_total",accType:"'debitor'"},{headers:{'auth-token':authInfo.token}}).then(res=>{
    //   debtor_balance_set(res.data.total_balance)
    // })

  }


  const getDailyBalances = async ()=>{
    await axios.post(`${API_URL}/api/get-debitor-rcv-record`,{fromDate,toDate},{headers:{'auth-token':authInfo.token}}).then(res=>{
      today_cash_set(res.data.reduce((prev,curr)=>{
        return prev+parseFloat(curr.rcv_total)
      },0))
    })



    await axios.post(`${API_URL}/api/get-indirect-expense-balance`,{fromDate,toDate,type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
      today_expense_set(res.data.total_balance)
    })
    
  // await axios.post(`${API_URL}/api/get-indirect-income-balance`,{fromDate,toDate,type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
  //   today_income_set(res.data.total_balance)
  // })

  await axios.post(`${API_URL}/api/get-sales-balance`,{fromDate,toDate,type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
    today_sale_set(res.data.total_balance)
  })

  // await axios.post(`${API_URL}/api/get-profit-loss-balance`,{fromDate,toDate,type:"head_total"},{headers:{'auth-token':authInfo.token}}).then(res=>{
  //   today_profit_loss_set(res.data.profitBalance)
  // })
  
  }

  

  
  const classes = useStyles();
  return(
    <>



{
 authInfo.acc_type != undefined &&  authInfo.acc_type  != 'customer' ? (

      <>
       
<Grid container>
  <Grid item xs={12} sm={4}><h5 style={{
         fontWeight: 'bold',fontFamily: 'cursive',margin: '0',padding: '0',textAlign:'left',fontSize:'16px',
         marginTop: '-25px',
         color:'#378100',
         marginBottom: '12px',marginLeft: '18px'}}>Branch  : ( {authInfo.userInfo.branch_name} ) {authInfo.userInfo.warehouse_name!=null?`Warehouse : (${authInfo.userInfo.warehouse_name})`:''} and  User  : ( {authInfo.userInfo.user_full_name} ) {authInfo.userInfo.warehouse_name!=null?`Warehouse : (${authInfo.userInfo.warehouse_name})`:''} </h5>
                  <p className="digital-watch" style={{color:'green',float: 'left',marginLeft: '10px'}}><DigitalClock /></p>

         </Grid>
         <Grid item xs={12} sm={4} style={{marginTop: '-10px'}}>
          <img src={APP_URL+'/title-logo.png'} />
         </Grid>
         <Grid item xs={12} sm={4} >



         <p className="date-text" style={{textAlign:'right'}}>{moment().format(dateFormat)} </p>

         </Grid>
</Grid>


{
  authInfo.role == 'super_admin' ? 
  <Grid container>
  <Grid item xs={12} sm={4}>
  <div class="styled-table-container">

    <table cellspacing="0" class="google-visualization-table-table">
      <thead>
        <tr class="google-visualization-table-tr-head">
          <th class="google-visualization-table-th-fullwidth" colspan="3" style={{background:'rgb(116 205 180)'}}>Sales of the Last 4 Months</th>
        </tr>
        <tr class="google-visualization-table-tr-head">
          <th class="google-visualization-table-th gradient unsorted">&nbsp;</th>
          <th class="google-visualization-table-th gradient unsorted" tabindex="0" role="button" aria-label="Sort column">Particular Month<span class="google-visualization-table-sortind"></span></th>
          <th class="google-visualization-table-th gradient google-visualization-table-type-number unsorted" tabindex="0" role="button" aria-label="Sort column" style={{textAlign:'right'}}>Sold Amount<span class="google-visualization-table-sortind"></span></th>
        </tr>
      </thead>
      <tbody>
        <tr class="google-visualization-table-tr-even google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">#</td>
          <td colspan="1" class="google-visualization-table-td">{getLast4MonthsDates().length != 0 ? moment(getLast4MonthsDates()[0].startDate).format('MMMM') : ''}</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{fourMonthSales.length != 0 ? format(parseFloat(fourMonthSales[0].total_amount).toFixed(2))  : 0} {authInfo.currency}</td>
        </tr>
        <tr class="google-visualization-table-tr-odd google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">#</td>
          <td colspan="1" class="google-visualization-table-td">{getLast4MonthsDates().length != 0 ? moment(getLast4MonthsDates()[1].startDate).format('MMMM') : ''}</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{fourMonthSales.length != 0 ? format(parseFloat(fourMonthSales[1].total_amount).toFixed(2))  : 0} {authInfo.currency}</td>
        </tr>
        <tr class="google-visualization-table-tr-even google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">#</td>
          <td colspan="1" class="google-visualization-table-td">{getLast4MonthsDates().length != 0 ? moment(getLast4MonthsDates()[2].startDate).format('MMMM') : ''}</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{fourMonthSales.length != 0 ? format(parseFloat(fourMonthSales[2].total_amount).toFixed(2))  : 0} {authInfo.currency}</td>
        </tr>
        <tr class="google-visualization-table-tr-odd google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">#</td>
          <td colspan="1" class="google-visualization-table-td">{getLast4MonthsDates().length != 0 ? moment(getLast4MonthsDates()[3].startDate).format('MMMM') : ''}</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{fourMonthSales.length != 0 ? format(parseFloat(fourMonthSales[3].total_amount).toFixed(2))  : 0} {authInfo.currency}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </Grid>  

  <Grid item xs={12} sm={4}>
  <div class="styled-table-container">

    <table cellspacing="0" class="google-visualization-table-table">
      <thead>
        <tr class="google-visualization-table-tr-head">
          <th class="google-visualization-table-th-fullwidth" colspan="3" style={{background:'#a4dfd7'}}>Today Summary</th>
        </tr>
        <tr class="google-visualization-table-tr-head">
          <th class="google-visualization-table-th gradient unsorted">&nbsp;</th>
          <th class="google-visualization-table-th gradient unsorted" tabindex="0" role="button" aria-label="Sort column">Particular Account<span class="google-visualization-table-sortind"></span></th>
          <th class="google-visualization-table-th gradient google-visualization-table-type-number unsorted" tabindex="0" role="button" aria-label="Sort column" style={{textAlign:'right'}}> Amount<span class="google-visualization-table-sortind"></span></th>
        </tr>
      </thead>
      <tbody>
        <tr class="google-visualization-table-tr-even google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">.</td>
          <td colspan="1" class="google-visualization-table-td">Today Due Collection	</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{format(parseFloat(today_cash).toFixed(2))} {authInfo.currency}</td>
        </tr>
        <tr class="google-visualization-table-tr-odd google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">.</td>
          <td colspan="1" class="google-visualization-table-td">Today Sales	</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{format(parseFloat(today_sale).toFixed(2))} {authInfo.currency}</td>
        </tr>
        <tr class="google-visualization-table-tr-even google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">.</td>
          <td colspan="1" class="google-visualization-table-td">Today Expense	</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{format(parseFloat(today_expense).toFixed(2))} {authInfo.currency}</td>
        </tr>
        <tr class="google-visualization-table-tr-odd google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">.</td>
          <td colspan="1" class="google-visualization-table-td">Today Profit/ Loss</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{format(parseFloat(today_profit_loss).toFixed(2))} {authInfo.currency}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </Grid>  
  <Grid item xs={12} sm={4}>
  <div class="styled-table-container">

    <table cellspacing="0" class="google-visualization-table-table">
      <thead>
        <tr class="google-visualization-table-tr-head">
          <th class="google-visualization-table-th-fullwidth" colspan="3" style={{background:'#79d1cd'}}>Balance Summary</th>
        </tr>
        <tr class="google-visualization-table-tr-head">
          <th class="google-visualization-table-th gradient unsorted">&nbsp;</th>
          <th class="google-visualization-table-th gradient unsorted" tabindex="0" role="button" aria-label="Sort column">Particular Account<span class="google-visualization-table-sortind"></span></th>
          <th class="google-visualization-table-th gradient google-visualization-table-type-number unsorted" tabindex="0" role="button" aria-label="Sort column" style={{textAlign:'right'}}>Balance<span class="google-visualization-table-sortind"></span></th>
        </tr>
      </thead>
      <tbody>
        <tr class="google-visualization-table-tr-even google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">:)</td>
          <td colspan="1" class="google-visualization-table-td">Cash In Hand	</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}> {format(parseFloat(cash_in_hand).toFixed(2))} {authInfo.currency}</td>
        </tr>
        <tr class="google-visualization-table-tr-odd google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">:)</td>
          <td colspan="1" class="google-visualization-table-td">Bank Accounts	</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{format(parseFloat(bank_balance).toFixed(2))} {authInfo.currency}</td>
        </tr>
        <tr class="google-visualization-table-tr-even google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">:)</td>
          <td colspan="1" class="google-visualization-table-td">Customer Due	</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{format(parseFloat(debtor_balance).toFixed(2))} {authInfo.currency}</td>
        </tr>
        <tr class="google-visualization-table-tr-odd google-visualization-table-tr-over">
          <td class="google-visualization-table-td google-visualization-table-seq">:)</td>
          <td colspan="1" class="google-visualization-table-td">Supplier Due	</td>
          <td colspan="1" class="google-visualization-table-type-number google-visualization-table-td" style={{textAlign:'right'}}>{format(parseFloat(creditor_balance).toFixed(2))} {authInfo.currency}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </Grid>     
</Grid> : ''
}



<div className="modules-box">
  <div  className="module-box"  >
    <Link to="/sales">
    <img   src={APP_URL+'/sales.png'} />
    </Link>
  </div>
  <div  className="module-box"  >
    <Link to="/accounts">
    <img   src={APP_URL+'/accounts.png'} />
    </Link>
  </div>
  <div  className="module-box"  >
    <Link to="/sales/pos-entry">
    <img   src={APP_URL+'/pos.png'} />
    </Link>
  </div>
  <div  className="module-box"  >
    <Link to="/purchase">
    <img   src={APP_URL+'/purchase.png'} />
    </Link>
  </div>
  <div  className="module-box"  >
    <Link to="/inventory">
    <img   src={APP_URL+'/inventory.png'} />
    </Link>
  </div>
 
  <div  className="module-box"  >
    <Link to="/manufacturing">
    <img   src={APP_URL+'/manufacturing.png'} />
    </Link>
  </div>

  <div  className="module-box"  >
    <Link to="/service">
    <img   src={APP_URL+'/service.png'} />
    </Link>
  </div>


  <div  className="module-box"  >
    <Link to="/reports">
    <img   src={APP_URL+'/reports.png'} />
    </Link>
  </div>
  <div  className="module-box"  >
    <Link to="/hrpayroll">
    <img   src={APP_URL+'/hr.png'} />
    </Link>
  </div>
  <div  className="module-box"  >
    <Link to="/order">
    <img   src={APP_URL+'/order.png'} />
    </Link>
  </div>
  <div  className="module-box"  >
    <Link to="/quotation">
    <img   src={APP_URL+'/quotation.png'} />
    </Link>
  </div>
  <div  className="module-box"  >
    <Link to="/crm">
    <img   src={APP_URL+'/crm.png'} />
    </Link>
  </div>
 

</div>

<Grid container>
  <Grid item xs={12} sm={4}>

  </Grid>
  <Grid item xs={12} sm={4}>
  <AnalogueClock {...clockOptions}   />

  </Grid>
  <Grid item xs={12} sm={4}>

  </Grid>
</Grid>

      </>

  ):<>


  
  </>
}

             
  </>
  )
}
const mapStateToProps = (state)=>{
  return{
    currentRoute:state.returnReducer,
    authInfo:state.authInfoReducer
  }
}
export default connect(mapStateToProps,{currentRouteSet})(Dashboard)